@font-face {
  font-family: "LexendDecaMed";
  src: url(/src/Components/Fonts/LexendDeca-Medium.ttf);
}

@font-face {
  font-family: "LexendDecaReg";
  src: url(/src/Components/Fonts/LexendDeca-Regular.ttf);
}

a {
  text-decoration: none;
  color: black;
}

.nav {
  font-family: "LexendDecaMed";
  list-style-type: none;
  display: flex;
}

.child {
  display: inline-block;
  padding: 20px;
  font-size: 20px;
  transition: all 0.35s ease-in-out;
}

.child:hover {
  transform: scale(1.1);
  color: grey;
}

@media screen and (max-width: 720px) {
  .child {
    display: inline-block;
    padding: 20px;
    font-size: 40px;
    transition: all 0.35s ease-in-out;
  }

  .child:hover {
    transform: scale(1.1);
    color: #78d4f7;
  }

  .nav {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: fixed;
    /* We want it off the screen when not toggled*/
    left: -100%;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: whitesmoke;
    z-index: 10;
    transition: left 1s;
  }

  .active {
    left: 0;
  }
}
